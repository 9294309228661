import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { useConfiguration } from '../../context/configurationContext';
import { connect } from 'react-redux';
import getDistance from 'geolib/es/getDistance';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import Vector from '../../assets/Vector.png';
import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    let formattedPrice = formatMoney(intl, price);
    formattedPrice = formattedPrice.replace('$', 'ש"ח').replace('.00', '');
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

function truncateTitle(str, length = 19) {
  if (str.length > length) {
    return str.substring(0, length) + '...';
  }
  return str;
}

function truncateDescription(str, length = 20) {
  if (str.length > length) {
    return str.substring(0, length) + '...';
  }
  return str;
}

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    position,
    pageName,
  } = props;

  let positionObj = {};
  if (!position) {
    positionObj = { lat: 32.085276, lng: 34.781794 }; // Default to origin if no position provided.
  } else {
    positionObj = { ...position };
  }
  const distance = Number.parseFloat(
    (
      getDistance(
        {
          latitude: listing?.attributes?.geolocation?.lat,
          longitude: listing?.attributes?.geolocation?.lng,
        },
        { latitude: positionObj.lat, longitude: positionObj.lng },
        1
      ) / 1000
    ).toFixed(1)
  );
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;
  let landingPageVariants = listing?.image?.attributes?.variants?.default?.url || '';
  let cityName =
    listing?.attributes?.publicData?.location?.address.split(',').map(part => part.trim())[1] || '';
  let description = '';
  if (listing?.attributes?.description) {
    description = truncateDescription(listing?.attributes?.description);
  }
  const landingPageTitle = truncateTitle(title);
  return (
    <>
      {pageName == 'landingPage' ? (
        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
          <AspectRatioWrapper
            className={css.aspectRatioWrapper}
            width={aspectWidth}
            height={aspectHeight}
            {...setActivePropsMaybe}
            pageName='landingPage'
          >
            {landingPageVariants && (
              <img
                src={landingPageVariants}
                alt="listing pic"
                width={198}
                height={148.5}
                className={css.imageListing}
              />
            )}
            {/* <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={landingPageFirstImage}
              variants={landingPageVariants}
              sizes={renderSizes}
            /> */}
          </AspectRatioWrapper>

          <div className={css.info}>
            <div className={css.mainInfo}>
              <div className={css.title}>
                {richText(landingPageTitle, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
              {description && <div className={css.description}>{description}</div>}
              <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
              {cityName && (
                <div className={css.distance}>
                  <img src={Vector} alt="vector" /> {cityName}
                </div>
              )}
              <div className={css.distance}>{distance} ק״מ ממך</div>
            </div>
          </div>
        </NamedLink>
      ) : (
        <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
          <AspectRatioWrapper
            className={css.aspectRatioWrapper}
            width={aspectWidth}
            height={aspectHeight}
            {...setActivePropsMaybe}
          >
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={variants}
              sizes={renderSizes}
            />
          </AspectRatioWrapper>

          <div className={css.info}>
            <div className={css.mainInfo}>
              <div className={css.title}>
                {richText(title, {
                  longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                  longWordClass: css.longWord,
                })}
              </div>
              {/* {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null} */}
              {description && <div className={css.description}>{description}</div>}
              <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
              {cityName && (
                <div className={css.distance}>
                  <img src={Vector} alt="vector" /> {cityName}
                </div>
              )}
              <div className={css.distance}>{distance} ק״מ ממך</div>
              <div className={css.forMoreInfo}>לפרטים</div>
            </div>
          </div>
        </NamedLink>
      )}
    </>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

const mapStateToProps = state => {
  return {
    position: state.user.position,
  };
};

export default compose(
  injectIntl,
  connect(mapStateToProps)
)(ListingCardComponent);
